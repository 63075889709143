import axios from "axios";
import "./App.css";

function App() {
  // const fetchConnectToken = () => {
  //   return localStorage.getItem("connectToken");
  // };

  const updateConnectToken = (newToken) => {
    localStorage.setItem("connectToken", newToken);
  };

  const fetchConnectToken = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const email = urlParams.get("email");

    axios({
      method: "post",
      url: "https://workflow.sandbox.kanmon.dev/api/v1/connect_token",
      data: {
        userEmail: email,
      },
      headers: {
        Authorization: "ApiKey 907ad3dd-f2c2-409c-8849-4b177a0720d3",
      },
    })
      .then(function (response) {
        updateConnectToken(response.data.connectToken);
      })
      .catch((err) => {
        console.log("caught error with request", email, err);
      });
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="App-content">
          <div>Tycho Station</div>
          <div style={{ marginTop: "40px" }}>
            Let us help you prevent another Eros Incident
          </div>
          <button onClick={fetchConnectToken}>Update Connect Token</button>
        </div>
      </header>
    </div>
  );
}

export default App;
